<template>
  <div id="employeeVacation" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-4 mt-5"
        >SOLICITUDES DE VACACIONES</v-row
      >
      <v-row no-gutters justify="end" class="mr-12">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-2">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="solicitudes()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addRequest" id="nuevoSucursal">
              <button class="breakSearchE botonAmarillo">
                Agregar solicitud
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-container fluid class="base text-center">
        <v-row
          no-gutters
          class="datos pa-0"
          style="height: auto !important; width: 100% !important"
        >
          <v-card
            style="width: 100%; background: rgb(199, 195, 199) !important"
          >
            <v-data-table
              :headers="headers"
              :items="nuevasSolicitudes"
              :items-per-page="5"
              :search="search"
              class="elevation-2 text--dark"
              loading-text="Cargando datos... Por favor espere"
              :no-results-text="'No se encontraron incidencias'"
              :no-data-text="'No hay datos'"
              :footer-props="{
                'items-per-page-text': 'Solicitudes por página',
                'items-per-page': [5, 10, 15],
                'items-per-page-all-text': 'Todas',
                'items-per-page-options': [5, 10],
                'loading-text': 'Obteniendo datos... Por favor espere',
                'no-data-text': 'No hay datos...',
                'no-results-text': 'No se encontraron incidencias',
              }"
            >
              <template v-slot:no-data>
                <v-alert :value="true" color="#ffffff" icon="warning">
                  <p style="color: black">No hay datos en sistema ...</p>
                </v-alert>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :class="{ selectedRow: item === selectedItem }"
                  >
                    <td class="text-sm-center">{{ item.id }}</td>
                    <td class="text-sm-center">
                      {{ item.empleadoSolicita.nombre }}
                      {{ item.empleadoSolicita.apellidoPaterno }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaSolicitud.replace("T", " ") }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaInicioVacaciones.substr(0, 10) }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.fechaFinVacaciones.substr(0, 10) }}
                    </td>
                    <td class="text-sm-center">
                      {{ item.estatus.descripcionEstatus }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      employee: localStorage.empleadoIdGlobal,
      role: localStorage.roles,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Nombre",
          align: "center",
          value: "empleadoSolicita.nombre",
        },
        {
          text: "Fecha solicitud",
          align: "center",
          value: "fechaSolicitud",
        },
        {
          text: "Fecha inicio vacaciones",
          align: "center",
          value: "fechaInicioVacaciones",
        },
        {
          text: "Fecha fin vacaciones",
          align: "center",
          value: "fechaFinVacaciones",
        },
        {
          text: "Estatus",
          align: "center",
          value: "estatus.descripcionEstatus",
        },
      ],
      nuevasSolicitudes: [],
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },

    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    solicitudes() {
      this.nuevasSolicitudes = [];
      this.show = true;
      axios
        .get(Server + "/vacaciones/solicitudes/" + this.enterprise +"/"+this.employee+"?role="+this.role, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.nuevasSolicitudes = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.solicitudes();
  },
};
</script>